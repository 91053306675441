@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #fff;
  --item-border: #e2e3e7;
  --plate-border: #e2e3e7;
  --item-background: #fff;
  --item-disabled-background: #f6f6f6;
  --item-secondary-background: #efefef;
  --dropdown-search-background: #f8f9fa;
  --section-border: #e2e3e7;
  --main-text: #111314;
  --secondary-text: #64686f;
  --border-secondary: #f3f3f3;
  --scrollbar-color: rgb(17 19 20 / 40%);
  --separator-color: #979797;
  --widget-border: #26a9e0;
  --safari-scroll-background: #fff;

  //intercom color
  --intercom-color: #26a9e0;

  //menu text colors
  --menu-item: #26a9e0;
  --menu-item-hover: #111314;
  --menu-icon: brightness(0) saturate(100%) invert(66%) sepia(15%) saturate(7171%) hue-rotate(164deg) brightness(93%) contrast(88%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(1%) sepia(21%) saturate(4558%) hue-rotate(151deg) brightness(93%) contrast(86%);

  //icons
  --icon-background: #26a9e0;
  --icon-color: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1667%) hue-rotate(45deg) brightness(112%) contrast(101%);
  --grey-text-icons: #767676;
  --icon-danger-background: #cc3030;
  --icon-danger-color: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(128deg) brightness(100%) contrast(105%);
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(82%) sepia(42%) saturate(7397%) hue-rotate(164deg) brightness(94%) contrast(86%);

  //wallet types
  --fiat-type: #cef1ff;
  --crypto-type: #e33e7c;
  --saving-type: #26a9e0;
  --market-chart: #26a9e0;

  //cards
  --card-text: #111314;
  --card-text-title: rgb(#{hex-to-rgb(#111314)} / 60%);
  --card-cvv: #111314;
  --logo-width: 12.9em; // only in em
  --fields-card: #e2e3e7;
  --card-logo: unset;
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #eaf7ff;
  --notification-background: #eef4ff;
  --gradient-color1: #e1f5fd;
  --gradient-color2: #fff;
  --main-red: #cc3030;
  --grey: #efefef;
  --grey-background: #f6f6f6;
  --grey-easy: #d9d9d9;
  --grey-primary: #888989;
  --light-red: #f9e6e6;
  --light-orange: #fbefe1;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #e5f5ed;
  --light-blue: #1e55b2;
  --orange: #e17c05;
  --hover-primary: #2e8aa8;
  --extra-light: #f7faff;
  --primary-text: #26a9e0;
  --button-primary: #26a9e0;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(50%) sepia(37%) saturate(1408%) hue-rotate(162deg) brightness(92%) contrast(89%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 26px;
  --dashboard-logo-width: 192px;
  --dashboard-logo-mobile-width: 52px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}
